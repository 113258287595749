import React from "react"
import styled from "styled-components"
import Layout_main from "../layouts/Layout_main.js"
import { darkAlt, lightestGrey } from "../styles/colors.js"
import { Container } from "../styles/containers.js"

export default function FourOhFour({ location }) {
	return (
		<Layout_main location={location}>
			<Page>
				<div>
					<h1 className="page-heading">NOT FOUND</h1>
					<span>|</span>
					<p>This page doesn't exist.</p>
				</div>
			</Page>
		</Layout_main>
	)
}

const Page = styled(Container)`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100%;

	&:before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: -250px;
		height: 900px;
		background: ${lightestGrey};
		transform: skew(0, -12deg);
		z-index: -1;
	}

	div {
		@media only screen and (max-width: 600px) {
			flex-direction: column;
		}
		display: flex;
		justify-content: center;
		align-items: center;
	}

	h1 {
		margin: 0;
	}

	span {
		@media only screen and (max-width: 600px) {
			display: none;
		}
		font-size: 3rem;
		margin: 0 0.5rem;
	}

	p {
		color: ${darkAlt};
		font-size: 1.5rem;
		font-weight: 400;
		margin: 0 0 -8px;
	}
`
